<template>
  <div class="qsui-admin-container">
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <template v-else-if="!isElemLoadedAndValid">
      <div class="qsui-admin-form col-lg-12 col-xl-12"> </div>
    </template>
    <template v-else>
      <div class="qsui-admin-form col-lg-12 col-xl-12">
        <div class="text-h4"
          >FundMasterData for Book: Q{{ elem.BookID.value }},
          {{ elem.Name.value }}, {{ elem.ExternalID.value }},
          {{ RS.oly_thk.value }}</div
        >
        <div class="text">{{ elem.Description.value }}</div>
        <last-modified
          :UserMod="RS.UserMod"
          :lastChanged="RS.lastChanged"
          objectName="FundMasterData"
        ></last-modified>
        <div class="qsui-admin-subform">
          <!-- Main -->
          <div class="qsui-admin-subform-row">
            <select-confmap
              class="input-field-small"
              label="Status"
              title="Status"
              map_type="fonds_status"
              v-model="RS.fonds_status"
            />
            <readonly-ersatz
              class="input-field-medium"
              label="oly_thk"
              title="oly_thk, DBSystem Fonds Kennung"
              :value="RS.oly_thk.value"
            />
            <select-boolean
              class="input-field-small"
              label="FundHero"
              title="Send to FundHero"
              v-model="RS.do_fund_hero"
            />
            <!-- <select-generic
              class="input-field-small"
              label="Currency"
              title="Währung"
              v-model="RS.currency"
              :options="['EUR', 'CHF', 'USD']"
            /> -->
            <readonly-ersatz
              class="input-field-small"
              label="Currency"
              title="Währung"
              :value="RS.currency"
            />
            <!-- <select-generic
              class="input-field-small"
              label="Adm.Ctry"
              title="Admission Country, Zulassungsland"
              :options="['LU', 'DE', 'CH']"
              v-model="RS.admission_country"
            /> -->
            <readonly-ersatz
              class="input-field-small"
              label="Adm.Ctry"
              title="Admission Country, Zulassungsland"
              :value="RS.admission_country"
            />
            <!-- <select-generic
              class="input-field-medium"
              label="NAV Freq."
              title="NAV Frequenz"
              :options="['daily', 'weekly', 'monthly', 'quarterly', 'yearly']"
              v-model="RS.NAVFrequency"
            /> -->
            <readonly-ersatz
              class="input-field-medium"
              label="NAV Freq."
              title="NAV Frequenz"
              :value="RS.NAVFrequency"
            />
            <!-- <select-confmap
              class="input-field-large"
              label="LawType"
              title="Gesetzesform"
              map_type="lawtype"
              v-model="RS.law_type"
            /> -->
            <readonly-ersatz
              class="input-field-large"
              label="LawType"
              title="Gesetzesform"
              map_type="lawtype"
              :value="RS.law_type"
            />
            <!-- <select-confmap
              class="input-field-large"
              label="Management Company"
              title="Verwaltungsgesellschaft"
              map_type="manco"
              v-model="RS.manco"
            /> -->
            <readonly-ersatz
              class="input-field-large"
              label="Management Company"
              title="Verwaltungsgesellschaft"
              map_type="manco"
              :value="RS.manco"
            />
            <!-- <select-generic
              class="input-field-medium"
              label="Limit Type"
              title="Limit-Typ"
              :options="['ABSOLUT', 'RELATIV', 'CA']"
              v-model="RS.LimitType"
            /> -->
            <readonly-ersatz
              class="input-field-medium"
              label="Limit Type"
              title="Limit-Typ"
              :value="RS.LimitType"
            />
            <!-- <select-book
              v-if="!!RS.segmentof"
              class="input-field-large"
              label="Segment of"
              title="Segment von"
              v-model="RS.segmentof"
            /> -->
            <readonly-ersatz
              class="input-field-large"
              label="Segment of"
              title="Segment von"
              :value="RS.segmentof"
            />
            <select-book
              v-if="!!RS.MasterFonds"
              class="input-field-large"
              label="MasterFund"
              title="Masterfonds"
              v-model="RS.MasterFonds"
            />
            <!-- <readonly-ersatz
              class="input-field-large"
              label="MasterFund"
              title="Masterfonds"
              :value="RS.MasterFonds"
            /> -->
            <select-boolean
              class="input-field-small"
              label="KRI"
              title="KRI ist aktiv"
              v-model="RS.doKRI"
            />
            <!-- <select-boolean
              class="input-field-small"
              label="Ignore trx"
              title="Ignore trx"
              v-model="RS.ignoreTrx"
            /> -->
            <readonly-ersatz
              class="input-field-small"
              label="Ignore trx"
              title="Ignore trx"
              :value="RS.ignoreTrx"
            />
            <select-boolean
              class="input-field-small"
              label="Cashflow"
              title="Cashflow Berechnung ist aktiv"
              v-model="RS.doCF"
            />
            <!-- <readonly-ersatz
              class="input-field-small"
              label="Cashflow"
              title="Cashflow Berechnung ist aktiv"
              :value="RS.doCF"
            /> -->
            <!-- <select-confmap
              class="input-field-small"
              label="XML Export"
              title="Kunden XML Export"
              map_type="client_xml_export"
              v-model="RS.client_xml_export"
            /> -->
            <readonly-ersatz
              class="input-field-small"
              label="XML Export"
              title="Kunden XML Export"
              map_type="client_xml_export"
              :value="RS.client_xml_export"
            />
            <!-- <select-date
              label="Fiscal Year End"
              title="Geschäftsjahresende"
              v-model="RS.fiscal_year_end"
            /> -->
            <readonly-ersatz
              class="input-field-medium"
              label="Fiscal Year End"
              title="Geschäftsjahresende"
              map_type="date"
              :value="RS.fiscal_year_end"
            />
            <sdx-textbox
              class="input-field-medium"
              label="Strategie"
              title="Fonds Strategie"
              :showLabel="true"
              v-model="RS.fund_strategie"
            />
            <sdx-textbox
              class="input-field-large"
              label="Initiator"
              title="Fonds Initiatoren"
              :showLabel="true"
              v-model="RS.fund_initiator"
            />
            <sdx-textbox
              class="input-field-small"
              label="niw ref shift"
              title="days shift between refDate and niwDate (0:ref=niw -> 3 Tage für GTA, 1 (default):ref=niw-1 -> 2 Tage für GTA)"
              type="text"
              :showLabel="true"
              :regex="config.regex.numberInputOrEmpty"
              v-model="RS.niw_ref_shift"
            />
            <select-date
              label="Reporting Start Date"
              title="Startdatum für das Reporting"
              v-model="RS.client_startdate"
            />
            <sdx-textbox
              class="input-field-large"
              label="Comment"
              title="Kommentar "
              :showLabel="true"
              v-model="RS.comment"
            />
          </div>
          <!-- PRIIPS -->
          <div class="text-h6">PRIIPS</div>
          <div class="qsui-admin-subform-row">
            <select-generic
              class="input-field-small"
              label="PRIIPS Pcat"
              title="PRIIPS Pcat"
              v-model="RS.PRIIPS_Pcat"
              :options="['1a', '1b', '1c', '2a', '2b', '3']"
            />
            <sdx-textbox
              class="input-field-medium"
              label="PRIIPS T"
              title="PRIIPS T (list of integers (days) separated by commas, Ex: 1,5,10,20)"
              type="text"
              :showLabel="true"
              v-model="RS.PRIIPS_T"
            />
            <select-generic
              class="input-field-small"
              label="PRIIPS Limit"
              title="PRIIPS LimitType"
              v-model="RS.PRIIPS_LimitType"
              :options="['none']"
            />
            <sdx-textbox
              class="input-field-medium"
              label="PRIIPS_CRM"
              title="PRIIPS_CRM"
              type="text"
              :showLabel="true"
              :regex="config.regex.numberInputOrEmpty"
              v-model="RS.PRIIPS_CRM"
            />
            <sdx-textbox
              class="input-field-medium"
              label="PRIIPS v lim"
              title="PRIIPS_v_lim"
              type="text"
              :showLabel="true"
              :regex="config.regex.numberInputOrEmpty"
              v-model="RS.PRIIPS_v_lim"
            />
            <sdx-textbox
              class="input-field-medium"
              label="PRIIPS q lim"
              title="PRIIPS_q_lim"
              type="text"
              :showLabel="true"
              :regex="config.regex.numberInputOrEmpty"
              v-model="RS.PRIIPS_q_lim"
            />
            <sdx-textbox
              class="input-field-medium"
              label="PRIIPS t var"
              title="PRIIPS_t_var"
              type="text"
              :showLabel="true"
              :regex="config.regex.numberInputOrEmpty"
              v-model="RS.PRIIPS_t_var"
            />
            <select-generic
              class="input-field-large"
              label="PRIIPS Benchmark"
              title="PRIIPS Benchmark"
              :options="riskBenchmarksData"
              v-model="RS.PRIIPS_Benchmark"
            />
            <bm-list :bmName="RS.PRIIPS_Benchmark" />
          </div>
          <!-- SRRI -->
          <div class="text-h6">SRRI</div>
          <div class="qsui-admin-subform-row">
            <select-boolean
              class="input-field-small"
              label="SRRI"
              title="SRRI ist aktiv"
              v-model="RS.doSRRI"
            />
            <!-- <readonly-ersatz
              class="input-field-small"
              label="SRRI"
              title="SRRI ist aktiv"
              :value="RS.doSRRI"
            /> -->
            <select-generic
              class="input-field-small"
              label="SRRI Type"
              title="SRRI Fondsart"
              v-model="RS.SRRI_Type"
              :options="['T', 'M']"
            />
            <!-- <readonly-ersatz
              class="input-field-small"
              label="SRRI Type"
              title="SRRI Fondsart"
              :value="RS.SRRI_Type"
            /> -->
            <select-date
              label="SRRI last Strategy change"
              title="SRRI letzte Strategieänderung"
              v-model="RS.SRRI_strategyChangeDate"
            />
            <!-- <readonly-ersatz
              class="input-field-medium"
              label="last Strategy change"
              title="SRRI letzte Strategieänderung"
              map_type="date"
              :value="RS.SRRI_strategyChangeDate"
            /> -->
            <select-generic
              class="input-field-large"
              label="SRRI Benchmark"
              title="SRRI Vergleichsvermögen"
              :options="riskBenchmarksData"
              v-model="RS.SRRI_Benchmark"
            />
            <!-- <readonly-ersatz
              class="input-field-large"
              label="SRRI Benchmark"
              title="SRRI Vergleichsvermögen"
              :value="RS.manco"
            /> -->
            <bm-list :bmName="RS.SRRI_Benchmark" />
          </div>
          <!-- MaRisk -->
          <div class="text-h6">MaRisk</div>
          <div class="qsui-admin-subform-row">
            <select-boolean
              class="input-field-small"
              label="MaRisk"
              title="MaRisk ist aktiv"
              v-model="RS.doMaRisk"
            />
            <!-- <readonly-ersatz
              class="input-field-small"
              label="MaRisk"
              title="MaRisk ist aktiv"
              :value="RS.doMaRisk"
            /> -->
            <select-generic
              class="input-field-medium"
              label="MaRisk Fundtype"
              title="MaRiskFondsart"
              :disabled="!RS.doMaRisk"
              :options="[
                '',
                'Aktienfonds',
                'Dachfonds',
                'Kreditfonds',
                'Mikrofinanzfonds',
                'Mischfonds',
                'Rentenfonds',
                'Spezialfonds',
                'Super OGAW',
                'Wandelanleihen',
                'Private Equity Fonds',
              ]"
              v-model="RS.MaRiskFondsart"
              v-show="RS.doMaRisk"
            />
            <!-- <readonly-ersatz
              class="input-field-medium"
              label="MaRisk Fundtype"
              title="MaRiskFondsart"
              :value="RS.MaRiskFondsart"
            /> -->
          </div>
          <!-- Liq Risk -->
          <div class="text-h6">Liquidity Risk</div>
          <div class="qsui-admin-subform-row">
            <select-confmap
              class="input-field-medium"
              label="LiqRiskClass"
              title="Risikoklasse LiqRisk"
              map_type="riskclass_liqrisk"
              v-model="RS.riskclass_liqrisk"
            />
            <select-confmap
              class="input-field-medium"
              label="LiqRiskStress"
              title="LiqRiskStress"
              map_type="liq_risk_stress"
              v-model="RS.liq_risk_stress"
            />
            <select-confmap
              class="input-field-medium"
              label="Fondskategorie (1-4)"
              title="LiqRisk"
              map_type="liq_risk_fund_category"
              v-model="RS.liq_risk_fund_category"
            />
            <sdx-textbox
              class="input-field-medium"
              label="Limit 1 Tages Stress"
              title="LiqRisk"
              type="text"
              :showLabel="true"
              :regex="config.regex.integer"
              v-model="RS.liq_stress_limit_1t"
            />
            <sdx-textbox
              class="input-field-medium"
              label="Limit 30 Tages Stress"
              title="LiqRisk"
              type="text"
              :showLabel="true"
              :regex="config.regex.integer"
              v-model="RS.liq_stress_limit_30t"
            />
          </div>
          <!-- Credit Risk -->
          <div class="text-h6">Credit Risk</div>
          <div class="qsui-admin-subform-row">
            <select-boolean
              class="input-field-small"
              label="CreditRisk"
              title="Kreditrisiko ist aktiv"
              v-model="RS.doCreditRisk"
            />
            <select-confmap
              class="input-field-medium"
              label="CreditRisk Class"
              title="Risikoklasse Kreditrisiko"
              map_type="riskclass_creditrisk"
              v-model="RS.riskclass_creditrisk"
              v-show="RS.doCreditRisk"
            />
            <div class="input-field">
              <i
                class="icon icon-060-play-filled wvfilter-icon"
                title="Calculate and fill Credit Limit based on Riskclass CreditRisk"
                aria-hidden="true"
                @click="setCreditLimit"
                v-show="RS.doCreditRisk"
              ></i>
            </div>
            <sdx-textbox
              class="input-field-medium"
              label="CreditRisk limit(%)"
              title="Kreditrisiko Limit"
              type="text"
              :showLabel="true"
              :regex="config.regex.decimal"
              v-model="RS.creditrisk_limit"
              v-show="RS.doCreditRisk"
            />
            <select-confmap
              class="input-field-medium"
              label="Limit avg. Rating"
              title="Limit für das Durchschnittsrating des Fonds"
              map_type="credit_risk_limit_rating"
              v-model="RS.credit_risk_limit_rating"
              v-show="RS.doCreditRisk"
            />
            <sdx-textbox
              class="input-field-medium"
              label="Limit High Yield (%)"
              title="Limit für Anteil High Yield Produkte am NAV"
              type="text"
              :showLabel="true"
              :regex="config.regex.integer"
              v-model="RS.credit_risk_limit_hy"
              v-show="RS.doCreditRisk"
            />
            <sdx-textbox
              class="input-field-medium"
              label="Limit High Risk (%)"
              title="Limit für Anteil High Risk Produkte (CoCo Bonds) am NAV"
              type="text"
              :showLabel="true"
              :regex="config.regex.integer"
              v-model="RS.credit_risk_limit_hr"
              v-show="RS.doCreditRisk"
            />
          </div>
          <!-- Op Risk -->
          <div class="text-h6">Operational Risk</div>
          <div class="qsui-admin-subform-row">
            <select-confmap
              class="input-field-small"
              label="OpRiskClass"
              title="Risikoklasse OpRisk"
              map_type="riskclass_oprisk"
              v-model="RS.riskclass_oprisk"
            />
          </div>
          <!-- Leverage & Commitment -->
          <div class="text-h6">Leverage and Commitment</div>
          <div class="qsui-admin-subform-row">
            <!-- <sdx-textbox
              class="input-field-large disable"
              label="Leverage Limit Sum of Notionals (%)"
              title="Limit des Leverages nach Nominalwertmethode"
              type="text"
              :showLabel="true"
              :regex="config.regex.decimal"
              v-model="RS.leverage_limit_notionals"
            /> -->
            <readonly-ersatz
              class="input-field-large"
              label="lev sum of notionals limit (%)"
              title="Limit des Leverages nach Nominalwertmethode"
              :value="RS.leverage_limit_notionals.value"
            />
            <!-- <sdx-textbox
              class="input-field-medium"
              label="ca limit (%)"
              title="Limit des Leverages nach Commitment-Methode"
              type="text"
              :showLabel="true"
              :regex="config.regex.decimal"
              v-model="RS.leverage_limit_ca"
            /> -->
            <readonly-ersatz
              class="input-field-medium"
              label="lev ca limit (%)"
              title="Limit des Leverages nach Commitment-Methode"
              :value="RS.leverage_limit_ca.value"
            />
            <!-- <sdx-textbox
              class="input-field-large"
              label="Gross method limit (%)"
              title="Limit des Leverages nach Bruttomethode"
              type="text"
              :showLabel="true"
              :regex="config.regex.decimal"
              v-model="RS.leverage_limit_bm"
            /> -->
            <readonly-ersatz
              class="input-field-medium"
              label="lev gross limit (%)"
              title="Limit des Leverages nach Bruttomethode"
              :value="RS.leverage_limit_bm.value"
            />
            <!-- <sdx-textbox
              class="input-field-large"
              label="Commitment Approach limit (%)"
              title="Commitment Approach Limit"
              type="text"
              :showLabel="true"
              :regex="config.regex.decimal"
              v-model="RS.ca_limit"
            /> -->
            <readonly-ersatz
              class="input-field-medium"
              label="ca limit (%)"
              title="Commitment Approach Limit"
              :value="RS.ca_limit.value"
            />
          </div>
          <!-- ESG -->
          <div class="text-h6">ESG</div>
          <div class="qsui-admin-subform-row">
            <!-- <select-confmap
              class="input-field-medium"
              label="sfdr classification"
              title="sfdr classification"
              map_type="sfdr_classification"
              v-model="RS.sfdr_classification"
            /> -->
            <readonly-ersatz
              class="input-field-medium"
              label="sfdr classification"
              title="sfdr classification"
              map_type="sfdr_classification"
              :value="RS.sfdr_classification"
            />
          </div>
        </div>
      </div>

      <div class="qsui-admin-form col-lg-12 col-xl-12">
        <div class="text-h6">Risk VaR Processes</div>
        <div class="qsui-admin-subform">
          <var-process
            v-for="rp in RS.varProcesses"
            v-bind:key="rp.name.id"
            class="qsui-admin-subform-row"
            :varProcess="rp"
            :admission_country="RS.admission_country"
            :law_type="RS.law_type"
            @isLegal="onclickLegal(rp)"
            @delete="delVarProcess($event)"
          />
          <div>
            <!-- <i
              class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
              title="Add VaR Process"
              aria-hidden="true"
              @click="addVarProcess()"
            ></i> -->
            <i
              class="icon icon-061-plus wvfilter-icon wvfilter-icon-disabled"
              title="Add VaR Process"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-12">
        <div class="text-h6">Perf Processes</div>
        <div class="qsui-admin-subform">
          <perf-process
            v-for="pp in RS.perfProcesses"
            v-bind:key="pp.name.id"
            class="qsui-admin-subform-row"
            :perfProcess="pp"
            @is_main="onclickIsMain(pp)"
            @delete="delPerfProcess($event)"
          />
          <div>
            <i
              class="icon icon-061-plus wvfilter-icon"
              title="Add Perf Process"
              aria-hidden="true"
              @click="addPerfProcess()"
            ></i>
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-12">
        <div class="qsui-admin-buttons">
          <a
            class="button button--secondary"
            href="javascript:void(0);"
            @click="onCancel"
            >cancel</a
          >
          <a
            class="button button--primary"
            :class="{ disabled: !hasChanged || !isModifyElemValid }"
            href="javascript:void(0);"
            @click="onSave"
            >Save FundMasterData</a
          >
        </div>
        <last-modified
          :UserMod="RS.UserMod"
          :lastChanged="RS.lastChanged"
          objectName="FundMasterData"
          class="text-align-right"
        ></last-modified>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";

import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import BMSFormForBook from "@/components/admin/BMSFormForBook";
import config from "@/data/config";
import selectGenericVue from "./inputForm/selectGeneric.vue";
import selectConfMapVue from "./inputForm/selectConfMap.vue";
import selectBookVue from "./inputForm/selectBook.vue";
import fmdVarProcessVue from "./fmdVarProcess.vue";
import fmdProcessVue from "./fmdPerfProcess.vue";
import bmIndexListVue from "./bmIndexList.vue";
import selectBooleanVue from "./inputForm/selectBoolean.vue";
import selectDateVue from "./inputForm/selectDate.vue";
import readonlyErsatzVue from "./inputForm/readonlyErsatz.vue";
import lastModifiedVue from "./lastModified.vue";
import {
  getLimit_creditrisk,
  getVal,
  getValV,
} from "@/components/admin/adminUtils.js";
import moment from "moment";
export default {
  data() {
    return {
      elem: {
        BookID: getValV(""),
        Name: getValV(""),
        ExternalID: getValV(""),
        Description: getValV(""),
        display: "",
      },
      RS: {
        //few definition that need watch
        currency: "EUR",
        oly_thk: "",
        doMaRisk: false,
        doCreditRisk: false,
        PRIIPS_Benchmark: "",
        SRRI_Benchmark: "",
        admission_country: "LU",
        law_type: -1,
        riskclass_creditrisk: -1,
        creditrisk_limit: getValV(40),
        leverage_limit_notionals: getValV(0),
        leverage_limit_ca: getValV(0),
        leverage_limit_bm: getValV(0),
        ca_limit: getValV(0),
        niw_ref_shift: getValV("0"),
        PRIIPS_CRM: -1,
        PRIIPS_v_lim: -1,
        PRIIPS_q_lim: -1,
        PRIIPS_t_var: -1,
        varProcesses: [],
        perfProcesses: [],
      },
      config: config,
      getValV: getValV,
    };
  },
  created() {
    document.title = "Admin>FundMasterData";
  },
  watch: {
    elemData: function (value) {
      this.elem.BookID = getValV(value.BookID);
      this.elem.Name = getValV(value.Name);
      this.elem.ExternalID = getValV(value.ExternalID);
      this.elem.Description = getValV(value.Description);
      this.elem.display = value.display;
    },
    fmdFundData: function (value) {
      this.RS.book_id = value.book_id;

      //frei text
      this.RS.comment = getValV(value.comment);
      this.RS.fund_strategie = getValV(value.fund_strategie);
      this.RS.fund_initiator = getValV(value.fund_initiator);
      this.RS.PRIIPS_T = getValV(value.PRIIPS_T);
      this.RS.oly_thk = getValV(value.oly_thk);

      //drop down
      this.RS.fonds_status = getVal(value.fonds_status, 2);
      this.RS.sfdr_classification = getVal(value.sfdr_classification, -1);
      this.RS.liq_risk_stress = getVal(value.liq_risk_stress, 10);
      this.RS.currency = getVal(value.currency, "EUR");
      this.RS.NAVFrequency = getVal(value.NAVFrequency, "daily");
      this.RS.LimitType = getVal(value.LimitType, "ABSOLUT");
      this.RS.law_type = getVal(value.law_type, 5);
      this.RS.MaRiskFondsart = getVal(value.MaRiskFondsart, "");
      this.RS.riskclass_oprisk = getVal(value.riskclass_oprisk, 1);
      this.RS.credit_risk_limit_rating = getVal(
        value.credit_risk_limit_rating,
        1
      );
      this.RS.riskclass_liqrisk = getVal(value.riskclass_liqrisk, 1);
      this.RS.riskclass_creditrisk = getVal(value.riskclass_creditrisk, 4);
      this.RS.admission_country = getVal(value.admission_country, "LU");
      this.RS.manco = getVal(value.manco, 1);
      this.RS.client_xml_export = getVal(value.client_xml_export, 1);
      this.RS.PRIIPS_Pcat = getVal(value.PRIIPS_Pcat, "");
      this.RS.PRIIPS_LimitType = getVal(value.PRIIPS_LimitType, "none");
      this.RS.SRRI_Type = getVal(value.SRRI_Type, "T");
      this.RS.liq_risk_fund_category = getVal(value.liq_risk_fund_category, 1);

      //book_id
      this.RS.MasterFonds = getVal(value.MasterFonds);
      this.RS.segmentof = getVal(value.segmentof);

      //Benchmark
      this.RS.PRIIPS_Benchmark = value.PRIIPS_Benchmark;
      this.RS.SRRI_Benchmark = value.SRRI_Benchmark;

      //bool
      this.RS.doMaRisk = value.doMaRisk;
      this.RS.doCF = value.doCF;
      this.RS.do_fund_hero = value.do_fund_hero;
      this.RS.ignoreTrx = value.ignoreTrx;
      this.RS.doKRI = value.doKRI;
      this.RS.doCreditRisk = value.doCreditRisk;
      this.RS.doSRRI = value.doSRRI;

      //float
      this.RS.creditrisk_limit = getValV(value.creditrisk_limit, 40);
      this.RS.leverage_limit_notionals = getValV(
        value.leverage_limit_notionals,
        0
      );
      this.RS.leverage_limit_ca = getValV(value.leverage_limit_ca, 0);
      this.RS.leverage_limit_bm = getValV(value.leverage_limit_bm, 0);
      this.RS.ca_limit = getValV(value.ca_limit, 0);
      this.RS.niw_ref_shift = getValV(value.niw_ref_shift, "0");
      this.RS.PRIIPS_CRM = getValV(value.PRIIPS_CRM, "");
      this.RS.PRIIPS_v_lim = getValV(value.PRIIPS_v_lim, "");
      this.RS.PRIIPS_q_lim = getValV(value.PRIIPS_q_lim, "");
      this.RS.PRIIPS_t_var = getValV(value.PRIIPS_t_var, "");
      this.RS.liq_stress_limit_1t = getValV(value.liq_stress_limit_1t, 15);
      this.RS.liq_stress_limit_30t = getValV(value.liq_stress_limit_30t, 45);
      this.RS.credit_risk_limit_hy = getValV(value.credit_risk_limit_hy, 5);
      this.RS.credit_risk_limit_hr = getValV(value.credit_risk_limit_hr, "0");

      //date
      this.RS.client_startdate = moment(
        value.client_startdate ? value.client_startdate : "1900-01-01"
      ).toDate();
      this.RS.fiscal_year_end = moment(
        value.fiscal_year_end ? value.fiscal_year_end : "1900-01-01"
      ).toDate();
      this.RS.SRRI_strategyChangeDate = moment(
        value.SRRI_strategyChangeDate
          ? value.SRRI_strategyChangeDate
          : "1900-01-01"
      ).toDate();

      //automatic
      this.RS.UserMod = value.UserMod;
      this.RS.lastChanged = value.lastChanged;

      this.RS.varProcesses = [];
      if (value.varProcesses) {
        value.varProcesses.forEach((element) => {
          this.RS.varProcesses.push({
            id: "rpid" + this.RS.varProcesses.length,
            name: getValV(element.name),
            description: getValV(element.description),
            method: getVal(element.method, "VCV"),
            confidenceLevel: getValV(element.confidenceLevel, 99),
            holdingPeriod: getValV(element.holdingPeriod, 20),
            degreesFreedom: getValV(element.degreesFreedom),
            limitAbs: getValV(element.limitAbs, 5),
            limitRel: getValV(element.limitRel, 125),
            stresstest_limit: getValV(element.stresstest_limit, 5),
            var_factor: getValV(element.var_factor, 1),
            riskclass_marketrisk: getVal(element.riskclass_marketrisk, 1),
            riskBenchmark: element.riskBenchmark,
            isLegal: element.isLegal,
          });
        });
      }

      this.RS.perfProcesses = [];
      if (value.perfProcesses) {
        value.perfProcesses.forEach((element) => {
          this.RS.perfProcesses.push({
            id: "ppid" + this.RS.perfProcesses.length,
            name: getValV(element.name),
            description: getValV(element.description),
            days_shift: getValV(element.days_shift),
            benchmark_name: element.benchmark_name,
            rebalancing: element.rebalancing,
            is_esg: element.is_esg,
            is_kiid: element.is_kiid,
            is_main: element.is_main,
          });
        });
      }
      // this.RS = JSON.parse(JSON.stringify(this.RS));
    },
  },
  computed: {
    ...mapGetters({
      elemData: "adminBook/bookData",
      isElemLoading: "adminBook/isBookLoading",
      fmdFundData: "fmdFund/fmdFundData",
      isFmdFundLoading: "fmdFund/isFmdFundLoading",
      riskBenchmarksData: "fmdRiskBenchmark/riskBenchmarksData",
    }),
    hasChanged() {
      if (!this.isModifyElemValid) {
        return false;
      }
      return true;
    },
    isElemLoadedAndValid() {
      return this.elem && this.elem.BookID.value > 0;
    },
    isModifyElemValid() {
      if (!this.isValidTextBox(this.RS.creditrisk_limit)) return false;
      if (!this.isValidTextBox(this.RS.leverage_limit_notionals)) return false;
      if (!this.isValidTextBox(this.RS.leverage_limit_ca)) return false;
      if (!this.isValidTextBox(this.RS.leverage_limit_bm)) return false;
      if (!this.isValidTextBox(this.RS.ca_limit)) return false;
      if (!this.isValidTextBox(this.RS.PRIIPS_q_lim)) return false;
      if (!this.isValidTextBox(this.RS.PRIIPS_v_lim)) return false;
      if (!this.isValidTextBox(this.RS.PRIIPS_t_var)) return false;
      if (!this.isValidTextBox(this.RS.PRIIPS_CRM)) return false;
      if (!this.isValidTextBox(this.RS.niw_ref_shift)) return false;
      if (!this.isValidTextBox(this.RS.liq_stress_limit_1t)) return false;
      if (!this.isValidTextBox(this.RS.liq_stress_limit_30t)) return false;
      if (!this.isValidTextBox(this.RS.credit_risk_limit_hy)) return false;
      if (!this.isValidTextBox(this.RS.credit_risk_limit_hr)) return false;

      var isValid = true;
      if (this.RS.varProcesses) {
        this.RS.varProcesses.forEach((element) => {
          // if (!this.isValidTextBox(element.name)) {
          //   isValid = false;
          //   return;
          // }
          // if (!this.isValidTextBox(element.description)) {
          //   isValid = false;
          //   return;
          // }
          // if (!this.isValidTextBox(element.degreesFreedom)) {
          //   isValid = false;
          //   return;
          // }
          // if (!this.isValidTextBox(element.confidenceLevel)) {
          //   isValid = false;
          //   return;
          // }
          // if (!this.isValidTextBox(element.holdingPeriod)) {
          //   isValid = false;
          //   return;
          // }
          // if (!this.isValidTextBox(element.limitAbs)) {
          //   isValid = false;
          //   return;
          // }
          // if (!this.isValidTextBox(element.limitRel)) {
          //   isValid = false;
          //   return;
          // }
          if (!this.isValidTextBox(element.stresstest_limit)) {
            isValid = false;
            return;
          }
          // if (!this.isValidTextBox(element.var_factor)) {
          //   isValid = false;
          //   return;
          // }
          // if (!element.riskBenchmark) {
          //   isValid = false;
          //   return;
          // }
        });
      }
      if (this.RS.perfProcesses) {
        this.RS.perfProcesses.forEach((element) => {
          if (!this.isValidTextBox(element.name)) {
            isValid = false;
            return;
          }
          if (!this.isValidTextBox(element.description)) {
            isValid = false;
            return;
          }
          if (!this.isValidTextBox(element.days_shift)) {
            isValid = false;
            return;
          }
          if (!element.benchmark_name) {
            isValid = false;
            return;
          }
        });
      }
      return isValid;
    },
    showSpinner() {
      return this.isElemLoading;
    },
  },
  methods: {
    get_text_for_readonly_date(rsvalue) {
      if (moment(rsvalue) > moment("1900-01-01")) {
        return getValV(moment(rsvalue).format("DD.MM.YYYY"));
      }
      return getValV("01.01.1900");
    },
    setCreditLimit() {
      let newlimit = getLimit_creditrisk(
        this.RS.law_type,
        this.RS.riskclass_creditrisk
      );
      this.RS.creditrisk_limit = getValV(newlimit);
    },
    isValidTextBox(o) {
      return !!o && o.valid;
    },
    addVarProcess() {
      this.RS.varProcesses.push({
        id: "rpid" + this.RS.varProcesses.length,
        name: getValV("new" + this.RS.varProcesses.length),
        description: getValV("varprocess"),
        confidenceLevel: getValV(99),
        holdingPeriod: getValV(20),
        degreesFreedom: getValV(0),
        method: "VCV",
        riskBenchmark: "",
        limitAbs: getValV(20),
        limitRel: getValV(200),
        stresstest_limit: getValV(200),
        var_factor: getValV(1),
        riskclass_marketrisk: 1,
        isLegal: this.RS.varProcesses.length == 0,
      });
    },
    addPerfProcess() {
      this.RS.perfProcesses.push({
        id: "ppid" + this.RS.perfProcesses.length,
        name: getValV("new" + this.RS.perfProcesses.length),
        description: getValV("perfprocess"),
        days_shift: getValV(0),
        benchmark_name: "",
        rebalancing: "daily",
        is_esg: 0,
        is_kiid: 0,
        is_main: this.RS.perfProcesses.length == 0,
      });
    },
    onclickLegal(rp) {
      this.RS.varProcesses.forEach((element) => {
        element.isLegal = rp.id == element.id;
      });
    },
    onclickIsMain(pp) {
      this.RS.perfProcesses.forEach((element) => {
        element.is_main = pp.id == element.id;
      });
    },
    delVarProcess(id) {
      if (this.RS.varProcesses) {
        //remove varProcess
        this.RS.varProcesses = this.RS.varProcesses.filter((e) => e.id !== id);
        //reset ids
        let i = 0;
        this.RS.varProcesses.forEach((element) => {
          element.id = "rpid" + i;
          i = i + 1;
        });
      }
    },
    delPerfProcess(id) {
      if (this.RS.perfProcesses) {
        //remove perfProcess
        this.RS.perfProcesses = this.RS.perfProcesses.filter(
          (e) => e.id !== id
        );
        //reset ids
        let i = 0;
        this.RS.perfProcesses.forEach((element) => {
          element.id = "ppid" + i;
          i = i + 1;
        });
      }
    },
    onCancel() {
      this.$store.dispatch("adminBook/CLEAR_BOOK");
    },
    onSave() {
      const payload = {
        book_id: this.RS.book_id ? this.RS.book_id : this.elem.BookID.value,

        //frei text
        comment: this.RS.comment.value,
        fund_strategie: this.RS.fund_strategie.value,
        fund_initiator: this.RS.fund_initiator.value,
        PRIIPS_T: this.RS.PRIIPS_T.value,
        oly_thk: this.RS.oly_thk.value,

        //drop down
        currency: this.RS.currency,
        fonds_status: this.RS.fonds_status,
        sfdr_classification: this.RS.sfdr_classification,
        liq_risk_stress: this.RS.liq_risk_stress,
        currency: this.RS.currency,
        NAVFrequency: this.RS.NAVFrequency,
        LimitType: this.RS.LimitType,
        law_type: this.RS.law_type,
        MaRiskFondsart: this.RS.MaRiskFondsart,
        riskclass_oprisk: this.RS.riskclass_oprisk,
        credit_risk_limit_rating: this.RS.credit_risk_limit_rating,
        riskclass_liqrisk: this.RS.riskclass_liqrisk,
        riskclass_creditrisk: this.RS.riskclass_creditrisk,
        admission_country: this.RS.admission_country,
        manco: this.RS.manco,
        client_xml_export: this.RS.client_xml_export,
        PRIIPS_Pcat: this.RS.PRIIPS_Pcat,
        PRIIPS_LimitType: this.RS.PRIIPS_LimitType,
        SRRI_Type: this.RS.SRRI_Type,
        liq_risk_fund_category: this.RS.liq_risk_fund_category,

        //book_id
        MasterFonds: this.RS.MasterFonds,
        segmentof: this.RS.segmentof,

        //Benchmark
        PRIIPS_Benchmark: this.RS.PRIIPS_Benchmark,
        SRRI_Benchmark: this.RS.SRRI_Benchmark,

        //bool
        doMaRisk: this.RS.doMaRisk,
        doCF: this.RS.doCF,
        do_fund_hero: this.RS.do_fund_hero,
        ignoreTrx: this.RS.ignoreTrx,
        doKRI: this.RS.doKRI,
        doCreditRisk: this.RS.doCreditRisk,
        doSRRI: this.RS.doSRRI,

        //float
        creditrisk_limit: this.RS.creditrisk_limit.value,
        leverage_limit_notionals: this.RS.leverage_limit_notionals.value,
        leverage_limit_ca: this.RS.leverage_limit_ca.value,
        leverage_limit_bm: this.RS.leverage_limit_bm.value,
        ca_limit: this.RS.ca_limit.value,
        niw_ref_shift: this.RS.niw_ref_shift.value,
        PRIIPS_CRM: this.RS.PRIIPS_CRM.value,
        PRIIPS_v_lim: this.RS.PRIIPS_v_lim.value,
        PRIIPS_q_lim: this.RS.PRIIPS_q_lim.value,
        PRIIPS_t_var: this.RS.PRIIPS_t_var.value,
        liq_stress_limit_1t: this.RS.liq_stress_limit_1t.value,
        liq_stress_limit_30t: this.RS.liq_stress_limit_30t.value,
        credit_risk_limit_hy: this.RS.credit_risk_limit_hy.value,
        credit_risk_limit_hr: this.RS.credit_risk_limit_hr.value,

        varProcesses: [],
        perfProcesses: [],
      };
      //date
      if (moment(this.RS.client_startdate) > moment("1900-01-01")) {
        payload.client_startdate = moment(this.RS.client_startdate).format(
          "YYYY-MM-DD"
        );
      }
      if (moment(this.RS.fiscal_year_end) > moment("1900-01-01")) {
        payload.fiscal_year_end = moment(this.RS.fiscal_year_end).format(
          "YYYY-MM-DD"
        );
      }
      if (moment(this.RS.SRRI_strategyChangeDate) > moment("1900-01-01")) {
        payload.SRRI_strategyChangeDate = moment(
          this.RS.SRRI_strategyChangeDate
        ).format("YYYY-MM-DD");
      }

      if (this.RS.varProcesses) {
        this.RS.varProcesses.forEach((element) => {
          payload.varProcesses.push({
            name: element.name.value,
            description: element.description.value,
            method: element.method,
            confidenceLevel: element.confidenceLevel.value,
            holdingPeriod: element.holdingPeriod.value,
            degreesFreedom: element.degreesFreedom.value,
            limitAbs: element.limitAbs.value,
            limitRel: element.limitRel.value,
            stresstest_limit: element.stresstest_limit.value,
            var_factor: element.var_factor.value,
            riskclass_marketrisk: element.riskclass_marketrisk,
            riskBenchmark: element.riskBenchmark,
            isLegal: element.isLegal,
          });
        });
      }

      if (this.RS.perfProcesses) {
        this.RS.perfProcesses.forEach((element) => {
          payload.perfProcesses.push({
            name: element.name.value,
            description: element.description.value,
            days_shift: element.days_shift.value,
            benchmark_name: element.benchmark_name,
            rebalancing: element.rebalancing,
            is_esg: element.is_esg,
            is_kiid: element.is_kiid,
            is_main: element.is_main,
          });
        });
      }
      this.$store.dispatch("fmdFund/SAVE_FMDFUND", payload);
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
    ButtonsRow,
    "bms-form-for-book": BMSFormForBook,
    "select-generic": selectGenericVue,
    "select-confmap": selectConfMapVue,
    "select-boolean": selectBooleanVue,
    "select-book": selectBookVue,
    "select-date": selectDateVue,
    "bm-list": bmIndexListVue,
    "var-process": fmdVarProcessVue,
    "perf-process": fmdProcessVue,
    "last-modified": lastModifiedVue,
    "readonly-ersatz": readonlyErsatzVue,
  },
};
</script>
